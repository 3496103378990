body {
  font-family: "Balsamiq Sans", cursive;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg stroke='%23CCC' stroke-width='0' %3E%3Crect fill='%23ccfff3' x='-60' y='-60' width='110' height='240'/%3E%3C/g%3E%3C/svg%3E");
}

.app {
  height: 100vh;
  width: 100%;
  text-align: center;
  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content {
  margin-top: 2.2rem;
  font-size: 1.1rem;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.4);
}

.dogPic {
  border-radius: 10px;
  max-width: 80%;
}

.spacedBtn {
  margin: 2rem !important;
}
